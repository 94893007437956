import { forwardRef } from 'react'
import { MenuButton as ChakraMenuButton } from '@chakra-ui/react'

// https://chakra-ui.com/docs/overlay/menu

export const MenuButton = forwardRef(({ ...props }, ref) => {
  return (
    <ChakraMenuButton ref={ref} {...props}>
      {props.children}
    </ChakraMenuButton>
  )
})
