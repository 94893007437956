import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

function Portal({ children }) {
  const [el, setEl] = useState(null)

  useEffect(() => {
    const rootElement = document.getElementById('portal-components')
    const divEl = document.createElement('div')
    rootElement.appendChild(divEl)
    setEl(divEl)
    return () => rootElement.removeChild(divEl)
  }, [])

  return el ? ReactDOM.createPortal(children, el) : null
}

export default Portal
