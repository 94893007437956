import { css, jsx } from '@emotion/react'
import { Box } from './chakra/Box'
import theme from '../constants/theme'

const styles = props => css`
  display: inline-block;
  flex-grow: ${props.max ? 1 : undefined};
  margin-left: ${props.space === 0 ? 0 : `calc(${props.space || 1} * ${theme.space[2]})`};
`

function HorizontalSpace({ children, max, space, css: propsCss = '', className = '', ...props }) {
  return (
    <Box {...props} className={`${propsCss} ${className}`} css={styles({ max, space })}>
      {children}
    </Box>
  )
}

export default HorizontalSpace
