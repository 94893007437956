import { forwardRef } from 'react'
import { MenuGroup as ChakraMenuGroup } from '@chakra-ui/react'

// https://chakra-ui.com/docs/overlay/menu

export const MenuGroup = forwardRef(({ ...props }, ref) => {
  return (
    <ChakraMenuGroup ref={ref} {...props}>
      {props.children}
    </ChakraMenuGroup>
  )
})
