import { forwardRef, useMemo } from 'react'
import { MenuItem as ChakraMenuItem } from '@chakra-ui/react'
import { css, jsx } from '@emotion/react'
import theme from '../../../constants/theme'
import { Link } from '../Link'

// https://chakra-ui.com/docs/overlay/menu

const styles = css`
  transition: all ${theme.transition.duration.normalSlow};
  box-shadow: none !important;
  text-decoration: none !important;

  &:active {
    background: ${theme.colors.primary[500]};
    color: ${theme.colors.white};
  }
`

export const MenuItem = forwardRef(({ css: propsCss = '', className = '', to, isExternal, ...props }, ref) => {
  const disabled = props.isDisabled || props.disabled
  const navigateProp = useMemo(
    () => (to && !disabled ? { href: to, as: Link, isExternal } : {}),
    [to, isExternal, disabled]
  )

  return (
    <ChakraMenuItem
      ref={ref}
      {...props}
      {...navigateProp}
      className={`${propsCss} ${className}`}
      css={styles}
      role="button"
    >
      {props.children}
    </ChakraMenuItem>
  )
})
