import { forwardRef } from 'react'
import { MenuOptionGroup as ChakraMenuOptionGroup } from '@chakra-ui/react'

// https://chakra-ui.com/docs/overlay/menu

export const MenuOptionGroup = forwardRef(({ ...props }, ref) => {
  return (
    <ChakraMenuOptionGroup ref={ref} {...props}>
      {props.children}
    </ChakraMenuOptionGroup>
  )
})
