import { forwardRef } from 'react'
import { MenuList as ChakraMenuList, useColorMode } from '@chakra-ui/react'
import { css, jsx } from '@emotion/react'
import theme from '../../../constants/theme'

// https://chakra-ui.com/docs/overlay/menu

const styles = colorMode => css`
  background: ${theme.colors.background[colorMode]};
  transition: background ${theme.transition.duration.background};
`

export const MenuList = forwardRef(({ css: propsCss = '', className = '', ...props }, ref) => {
  const { colorMode } = useColorMode()

  return (
    <ChakraMenuList ref={ref} {...props} className={`${propsCss} ${className}`} css={styles(colorMode)}>
      {props.children}
    </ChakraMenuList>
  )
})
